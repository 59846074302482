<template>
    <div class="layout-device-config">

        <!--s: header-->
        <div class="config-header">
            <div class="header-left">
                <a href="javascript:" class="back" @click="onBack"><Icon type="ios-arrow-back" /></a>
                <Input v-model="configParams.configName" maxlength="20" :border="configNameVisible" @on-focus="configNameVisible = true" @on-blur="configNameVisible = false" :class="configNameVisible?'center':'left'"  placeholder="未命名" />
            </div>

            <div class="config-operate" v-if="configParams.fileUrl!==''">
                <Tooltip content="标记部件" v-if="!relevanceBoxVisible"><a href="javascript:" class="before" @click="configTabStatus = !configTabStatus" :class="configTabStatus?'fixed-blue':'fixed-black'"></a></Tooltip>
                <a href="javascript:" v-if="relevanceBoxVisible " class="before fixed-grey"></a>
                <Tooltip content="清除标记"> <a href="javascript:" @click="onClearTab" class="clean-up before"></a></Tooltip>
                <Tooltip content="换张图"><a href="javascript:" class="break before" @click="onUpdateImage"></a><input type="file" @change="onConfigFileChange($event,'updateImage')" ref="inputer" class="upload-input"></Tooltip>
                <div class="config-zoom">
                    <a href="javascript:" @click="onImageNarrow" class="minus-sign"></a>
                    <Tooltip content="适应画布"><a href="javascript:" class="number" @click="onImageInitial">{{ imageRatio }}%</a></Tooltip>
                    <a href="javascript:" @click="onImageEnlarge" class="plus-sign"></a>
                </div>
            </div>

            <div class="header-right" v-if="configParams.fileUrl!==''">
                <a href="javascript:" class="icon" @click="onSave">
                    <Tooltip content="手动保存" placement="bottom">
                        <Icon custom="image hold" />
                    </Tooltip>
                </a>
<!--                <a href="javascript:" class="icon" @click="onRelease(1)" v-if="configParams.status === 0">-->
<!--                    <Tooltip content="发布" placement="bottom">-->
<!--                        <Icon custom="image upload" />-->
<!--                    </Tooltip>-->
<!--                </a>-->
<!--                <a href="javascript:" class="icon" @click="onRelease(0)" v-if="configParams.status === 1">-->
<!--                    <Tooltip content="撤回" placement="bottom">-->
<!--                        <Icon custom="image recall" />-->
<!--                    </Tooltip>-->
<!--                </a>-->
                <a href="javascript:" class="icon" @click="onDownLoadImage" v-if="this.configParams.fileUrl!==''">
                    <Tooltip content="下载" placement="bottom">
                        <Icon custom="image down-load" />
                    </Tooltip>
                </a>
            </div>
        </div>
        <!--e: header-->

        <!--s: body-->
        <div class="config-body" >

            <div class="config-scroller">

                <!--s: 新建-->
                <div class="config-add" v-if="electricalId === '' && configParams.fileUrl === ''">
                    <div class="box">
                        <div class="add-icon" v-if="!speedSingleVisible">
                            <a href="javascript:">
                                <Icon type="ios-add" :size="30"/>
                            </a>
                        </div>
                        <div class="add-text" v-if="!speedSingleVisible">添加图片<br/>图片文件大小不超过10M</div>
                        <div class="progress" v-if="speedSingleVisible">
                            <Progress :percent="complete" />
                        </div>
                        <input type="file" v-if="!speedSingleVisible" accept="image/*" ref="pathClear" class="file" @change="onConfigFileChange($event)">
                    </div>
                </div>
                <!--e: 新建-->

                <!--s: 标记列表-->
                <div class="tab-list" v-if="configParams.fileUrl!==''">
                    <span class="tab-title">关联部件</span>
                    <div class="list">
                        <ul>
                            <li :class="tabs.signFixedInfoStatus?'active':''" :key="key" v-for="(tabs,key) in configParams.configData" @click.stop @click="onSignFixed(tabs,key)"><a href="javascript:">{{key+1}}.{{ tabs.partsName }}</a></li>
                        </ul>
                        <span class="no-data" v-if="configParams.configData.length === 0">暂无内容</span>
                    </div>
                    <div class="look" v-if="JSON.stringify(configSelectObj) !== '{}' && !configSelectObj.signFixedStatus && !selectBoxTabs">
                        <div class="look-title">
                            <span class="look-text">缩略图</span>
                            <a href="javascript:" class="look-a" @click="onLookImage"></a>
                        </div>
                        <div class="look-content">
                            <span class="look-image" v-if="configSelectObj.fileContent === 'image'" :style="'background-image:url('+imageSrc+')'"></span>
                            <video v-if="configSelectObj.fileContent === 'video'" width="100%" height="100%" :src="imageSrc"  controls class="file-video"></video>
                        </div>
                    </div>
                </div>
                <!--e: 标记列表-->

                <!--s: 操作区域-->
                <div class="config-content" v-if="configParams.fileUrl!==''">
<!--                    <div class="config-tips" v-if="configParams.status === 1">-->
<!--                        <div class="box"><Icon custom="info-icon"></Icon> 当前图册已发布，仅查看不可编辑</div>-->
<!--                    </div>-->
                    <div class="config-image-body" :class="!configTabStatus?'mouse':''"
                         @mousewheel="onMousewheel"
                         @mousedown="onMouseDown"
                         @mouseup="onMouseUp"
                         @mouseout="onMouseUp"
                         @mousemove="onMouseMove"
                         :style="'width:'+(imageWidth*(imageRatio/100))+'px;height:'+(imageHeight*(imageRatio/100))+'px;transform:translate('+imageLeft+'px,'+imageTop+'px)'">
                        <img :src="configParams.fileUrl"/>
                        <div class="config-sign" v-if="configTabStatus"
                             @mousedown="onSignMouseDown"
                             @mouseup="onSignMouseUp"
                             @mouseout="onSignMouseUp"
                             @mousemove="onSignMouseMove"
                        ></div>
                        <div class="sign" @mousedown.stop :class="!sign.signFixedStatus?'active-box':''" :key="key" v-for="(sign,key) in configParams.configData" :style="'left:'+sign.x+'%;top:'+sign.y+'%;width:'+sign.width+'%;height:'+sign.height+'%;'"></div>
                        <div class="sign-fixed" @mousedown.stop :key="'fixed'+key" v-for="(sign,key) in configParams.configData" :style="'left:'+(sign.x+sign.width/2)+'%;top:'+(sign.y+sign.height/2)+'%'">
                            <a href="javascript:" v-if="sign.signFixedStatus" @click.stop="onSignFixed(sign)">{{ key+1 }}</a>
                        </div>
                        <div class="sign-box"
                             @mousedown.stop="onSignBoxMouseDown"
                             @mouseup="onSignBoxMouseUp"
                             @mouseout="onSignBoxMouseUp"
                             @mousemove="onSignBoxMouseMove"
                             :class="relevanceBoxVisible?'move':'event'"
                             v-if="signSelectVisible"
                             :style="'transform:translate('+(signLeft/100*(imageWidth*(imageRatio/100)))+'px,'+(signTop/100*(imageHeight*(imageRatio/100)))+'px);width:'+signWidth+'%;height:'+signHeight+'%;'">

                        </div>
                        <div class="sign-look" @mousedown.stop v-if="configSelectObj.signFixedInfoStatus" :style="'right:'+(100-(configSelectObj.x+configSelectObj.width))+'%;bottom:'+(100-configSelectObj.y+2)+'%'">
                            <span class="name">{{ configSelectObj.partsName }}</span>
                            <div class="edit">
                                <a href="javascript:" @click="onLookImage"><Icon type="md-eye" size="18"/></a>
                                <a href="javascript:" v-if="configParams.status !== 1" @click.stop="onEditTabs"><Icon type="md-create" size="18"/></a>
                                <a href="javascript:" v-if="configParams.status !== 1" @click.stop="onDeleteTabs"><Icon type="ios-trash-outline" size="18"/></a>
                            </div>
                        </div>
                        <div class="sign-relevance" @mousedown.stop v-if="relevanceBoxVisible" :style="'right:'+(100-(signLeft+signWidth))+'%;top:'+(signTop+signHeight+0.6)+'%'">
                            <a href="javascript:" @click.stop="onSignTab" :class="selectBoxTabs?'select':''"><Icon type="md-link" size="20"/></a>
                            <a href="javascript:" @click.stop="onSignClear"><Icon type="ios-trash-outline" size="20"/></a>
                            <a href="javascript:" @click.stop="onSignAdd" :class="configObj.partsId === ''?'unchecked':'checked'"><Icon type="md-checkmark" size="20"/></a>

                            <div class="sign-select-tabs" v-if="selectBoxTabs">
                                <Cascader v-model="configSelectObj.partsId" :data="tabArray" change-on-select @on-change="onSelectTab" @on-visible-change="onOpenChange"/>
                            </div>
                        </div>
                    </div>
                    <Spin fix v-if="imageLoading"></Spin>
                </div>
                <!--e: 操作区域-->

            </div>

        </div>
        <!--e: body-->

        <Spin fix v-if="configLoading"></Spin>

        <!--s: Modal 查看大图-->
        <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc" />
        <!--e: Modal 查看大图-->
    </div>
</template>
<script>
import Electrical from './electrical'
export default Electrical
</script>
<style lang="less">
@import "electrical";
</style>
